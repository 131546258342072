import Odoo from 'odoo-xmlrpc';
import { OdooConfig, idClienteFinal, idClienteFinalCardoso } from '../utilities/odoo-config';
import { Desencrypt, Encrypt } from '../utilities/cipher';
let listProducts = {};
export const Login = async (data, call) => {
    const config = await OdooConfig();
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    console.log("odoo conect",odoo)
    odoo.connect(async (err, response) => {
        //console.log("error del logeo",err)
        console.log("response del logeo", response)
        if (err) {
            call(1, false)
        } else {
            //console.log("se logeo")
            //setData(odoo, call, data);
            await GetClient(odoo, call, data, response)
            data['id'] = response
        }
    })
}

export const GetClient = async (clientConect, callBack, data, uid) => {
    console.log("client del conect: ", clientConect)
    const config = await OdooConfig();
    const data2 = await Desencrypt();
    console.log("data del GetClient", data2)
    const args = [[0, { id: uid }]]
    const params = {
        model: "movilgo.webservice",
        method: "obtenerDatosCliente",
        args: args,
        kwargs: {}
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    console.log("args del getclient del login", args)
    client.connect((err, response) => {
        //console.log("error del conect del getclient",err)
        if (err) {
            return callBack(err, false);
        } else {
            client.execute_kw(params.model, params.method, params.args, async (err, response) => {
                console.log("response del service obtenerDatosCliente", response)
                console.log("err del service obtenerDatosCliente", err)
                if (err) {

                    return callBack(err, false);
                }
                localStorage.setItem("dataUser", JSON.stringify(response));
                setData(client, callBack, data);
                //return callBack(response, true);

            });

        }
    })



}

export const setData = async (odoo, call, user) => {
    //console.log("setData odoo",odoo)
    odoo.connect((err, response) => {
        //console.log("setData err",err)
        //console.log("setData response",response)
        if (err) {
            return call(response, false)
        } else {
            const args = [[
                0,
                { version: 1.3, product_write_date: null, error_write_date: null, documento_write_date: null }
            ]]
            const paramsOdoo = {
                model: "movilgo.webservice",
                method: "asignarDatos",
            };
            return odoo.execute_kw(paramsOdoo.model, paramsOdoo.method, args, async (err, response) => {
                console.log("error del pedir datos", err)
                console.log("response del pedir datos", response)
                if (err) {
                    call(2, false, err);
                    return false;
                } else {
                       
                    if (response.parent_id == idClienteFinal) {
                        localStorage.setItem("idDelPadre" ,"movilgo")
                        /* console.log("id del padre del if movilgo") */
                    } else {
                        if (response.parent_id == idClienteFinalCardoso) {
                            localStorage.setItem("idDelPadre","cardoso")
                           /* console.log("id del padre del if cardoso") */
                        }
                    }
                    localStorage.setItem("IdPadreCliente" ,response.parent_id)
                    

                    const getIdDelPadre = async () => {
                        const idDelPadre = await localStorage.getItem('idDelPadre');
                        console.log("id del padre:", idDelPadre);
                      };
                      
                      getIdDelPadre();
                      

                    let data = {};

                    if (response.permiso === 'cobrador') {
                        data = {
                            user: user,
                            products: {}
                        }
                        if (response.errores) {
                            SaveErrores(response.errores)
                        }
                        localStorage.setItem("Permission", "" + response.permiso);
                        call(data, true)
                    } else {
                        //console.log("trajo los productos")
                        if (response.productos) {
                            //console.log("response.productos",response.productos)
                            SaveProducts(response.productos);
                        }
                        if (response.errores) {
                            SaveErrores(response.errores)
                        }
                        localStorage.setItem("Permission", "" + response.permiso);
                        data = {
                            user: user,
                            products: listProducts
                        }
                        if (response) {
                            call(data, true)
                        } else {
                            call(0, false)
                        }
                    }

                }
            });
        }
    })
}

const SaveErrores = async (errores) => {
    localStorage.setItem("Errores", JSON.stringify(errores));
}

const SaveProducts = async (products) => {
    listProducts = {};
    products.forEach(element => {
        if (element.active) {
            if (element.categ_id_name === "Recargas") {
                if (listProducts.recarga) {
                    listProducts.recarga.push(element)
                } else {
                    listProducts['recarga'] = [];
                    listProducts.recarga.push(element)
                }
            } else if (element.categ_id_name === "Todo incluido" ||
                element.categ_id_name === "Voz" ||
                element.categ_id_name === "Datos" ||
                element.categ_id_name === "Voz y Datos" ||
                element.categ_id_name === "TV" ||
                element.categ_id_name === "Aplicaciones") {
                if (listProducts.paquete) {
                    listProducts.paquete.push(element);
                } else {
                    listProducts['paquete'] = [];
                    listProducts.paquete.push(element)
                }
            } else if (element.categ_id_name === "Rifas") {

                if (listProducts.rifas) {
                    listProducts.rifas.push(element)
                } else {
                    listProducts['rifas'] = [];
                    listProducts.rifas.push(element)
                }
            } else if (element.categ_id_name === "TV") {
                if (listProducts.tv) {
                    listProducts.tv.push(element)
                } else {
                    listProducts['tv'] = [];
                    listProducts.tv.push(element)
                }
            } else if (element.categ_id_name === "Pines") {
                if (listProducts.pines) {
                    listProducts.pines.push(element)
                } else {
                    listProducts['pines'] = [];
                    listProducts.pines.push(element);
                }

            } else if (element.tipo === "pines") {
                if (listProducts.paquetesPines) {
                    listProducts.paquetesPines.push(element)
                } else {
                    listProducts['paquetesPines'] = [];
                    listProducts.paquetesPines.push(element);
                }
            } else if (element.categ_id_name === "Mitecnova") {
                if (listProducts.mitecnova) {
                    listProducts.mitecnova.push(element)
                } else {
                    listProducts['mitecnova'] = [];
                    listProducts.mitecnova.push(element);
                }
            } else if (element.categ_id_name === "Recargas deportivas") {
                if (listProducts.recargasDeportivas) {
                    listProducts.recargasDeportivas.push(element)
                } else {
                    listProducts['recargasDeportivas'] = [];
                    listProducts.recargasDeportivas.push(element)
                }
            } else if (element.categ_id_name === "Soat") {
                if (listProducts.soats) {
                    listProducts.soats.push(element)
                } else {
                    listProducts['soats'] = [];
                    listProducts.soats.push(element)
                }
            } else if (element.name === "Carga Bolsa") {
                localStorage.setItem("CargaBolsa", element.id);
            } else if (element.categ_id_name === "Bingo") {
                if (listProducts.bingo) {
                    listProducts.bingo.push(element)
                } else {
                    listProducts['bingo'] = [];
                    listProducts.bingo.push(element)
                }
            } else if (element.categ_id_name === "Certificados") {
                if (listProducts.certificados) {
                    listProducts.certificados.push(element)
                } else {
                    listProducts['certificados'] = [];
                    listProducts.certificados.push(element)
                }
            }
        }
    })

}



export const newLogin = async (call) => {

    const user = await Desencrypt();
    const data = { user: user.user, password: user.password };
    //console.log("data del nuevo logeo",data)

    Login(data, (res, flag) => {
        //console.log("flag del nuevo login",flag)
        //console.log("res del nevo login",res)
        if (flag) {
            Encrypt(res.user);
            call(flag)

        }

    })

}