import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import uuidv4 from "uuid";
import { config } from "react-spring";
import sorteo1 from '../../../assets/cardoso/Sorteo_Millonario_1.jpg';
import sorteo2 from '../../../assets/cardoso/Sorteo_Millonario_2.jpg';
import '../../cardoso/cardoso.css';

export default class CarruselCardoso extends Component {
  state = {
    goToSlide: 0,
    goToSlideDelay: 4000,
    offsetRadius: 2,
    showNavigation: false,
   
    data: []
  };

  componentDidMount() {
    this.setState({
      data: [
        {
          key: uuidv4(),
          content: <img className="img1" src={sorteo1} style={{marginLeft: '125%',maxHeight: '150%',marginTop: '33%'}} alt="1" />,
        },
        {
          key: uuidv4(),
          content: <img className="img2" src={sorteo2} style={{marginLeft: '125%',maxHeight: '150%',marginTop: '33%'}} alt="2" />,
        }
      ].map((slide, index) => ({
        ...slide,
        onClick: () => this.setState({ goToSlide: index })
      }))
    });

    this.startAutoplay();
  }

  componentWillUnmount() {
    this.stopAutoplay();
  }

  startAutoplay = () => {
    this.autoplayInterval = setInterval(() => {
      const { data } = this.state;
      const currentSlide = this.state.goToSlide;
      const nextSlide = (currentSlide + 1) % data.length;
      this.setState({ goToSlide: nextSlide });
    }, this.state.goToSlideDelay);
  };

  stopAutoplay = () => {
    clearInterval(this.autoplayInterval);
  };

  render() {
    return (
      <div style={{ width: "90%", height: "500px", margin: "0 auto" }}>
        <Carousel
          slides={this.state.data}
          goToSlide={this.state.goToSlide}
          goToSlideDelay={this.state.goToSlideDelay}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          animationConfig={this.state.config}
        />
      </div>
    );
  }
}
