import './App.css';
import React, { PureComponent } from 'react';

import Header from './shared/header/header';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import RoutesHome from './routes';
import Footer from './shared/footer';
import FooterCardoso from './shared/footer_cardoso';
import { idClienteFinal } from './utilities/odoo-config';

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deslogear: false
    };
  }

  render() {
    const currentPath = window.location.pathname;
    let isCardosoRoute = false
    const id_padre = localStorage.getItem('idDelPadre')
    if (currentPath === '/cardoso' || id_padre=="cardoso") {
      isCardosoRoute = true
    }else {
      isCardosoRoute = false
    }

    return (
      <ToastProvider>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
        <div>
          <div style={{ index: 2, display: 'inlineBlock', maxWidth: '100%', height: '90%' }}>
            <RoutesHome deslogear={(flag) => { this.setState({ deslogear: flag }) }} />
          </div>
          <div style={{ position: 'relative', index: 3, display: 'inlineBlock', height: '10%', maxWidth: '100%' }}>
            {isCardosoRoute ? <FooterCardoso /> : <Footer />}
          </div>
        </div>
      </ToastProvider>
    );
  }
}

export default App;
