import React, { PureComponent } from 'react';
import 'react-toastify/dist/ReactToastify.css';
//import { cambiarContrasena } from '../../../services/nuevaContraseña.service';
import { ValidarBoleta } from '../../../services/rifa.service';
import BoletaMobile from './boletaMobile';
import BoletaRotateMobile from './boletaRotateMobile'
import DesktopView from './boletaDesktop';
import './styles.css'


class VolanteBoleta extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isRotateMobile: false, 
            datosRifa: [],
            numResolucion: '',
            images: [],
            currentImageIndex: 0,
            isFlipped: false,
            loading: true,
        };
    }

    componentDidMount() {
        this.checkMobile(); // Verificar si se está visualizando desde un celular al cargar el componente
        window.addEventListener('resize', this.checkMobile); // Verificar también cuando se cambia el tamaño de la pantalla
        this.loaData()
    }



    loaData = async () => {

        let url = window.location.search.split("id=");
        //console.log("validar boleta rifa",rifa)
        //console.log("url del validar voleta",url)
        let id = url[1];
        console.log("id de la busqueda", id)

        await ValidarBoleta(id, (flag, response) => {
            console.log("flag del  ValidarBoleta", flag);
            console.log("response del  ValidarBoleta", response);
            if (flag) {
                const datosRifaArray = response.datos.map((dato) => dato);
                this.setState({
                    datosRifa: datosRifaArray,
                    images: datosRifaArray[0].url_image,
                    numResolucion: datosRifaArray[0].numero_resolucion,
                    loading: false
                  }, () => {
                    // Verificar si el estado se actualizó correctamente
                    console.log("Estado actualizado, images:", this.state.images);
                  })
            } else {
                alert("Error", "Se presento un error al validar la boleta");
                this.setState({ loading: false })
            }
        });
    }

    checkMobile = () => {
        const { isMobile } = this.state;
        const isMobileView = window.innerWidth < 650;
        const isRotateView = window.innerWidth > 650 && window.innerWidth < 1000;

        if (isMobileView && !isMobile) {
            this.setState({ isMobile: true, isRotateMobile: false });
        } else if (!isMobileView && isMobile) {
            this.setState({ isMobile: false, isRotateMobile: false });
        } else if (isRotateView) {
            this.setState({ isMobile: false, isRotateMobile: true });
        }
    };

    render() {
        const { isRotateMobile, isMobile, images, currentImageIndex, loading, datosRifa, numResolucion } = this.state;

        let content = null;

        if (isMobile) {
            content = (

                <>
                    <BoletaMobile
                        isRotateMobile={isRotateMobile}
                        isMobile={isMobile}
                        images={images}
                        currentImageIndex={currentImageIndex}
                        loading={loading}
                        datosRifa={datosRifa}
                        numResolucion = {numResolucion} />
                </>

            );
        } else if (isRotateMobile) {
            content = (
                <>
                    <BoletaRotateMobile
                        isRotateMobile={isRotateMobile}
                        isMobile={isMobile}
                        images={images}
                        currentImageIndex={currentImageIndex}
                        loading={loading}
                        datosRifa={datosRifa}
                        numResolucion = {numResolucion} />
                </>
            );
        } else {
            content = (
                <>
                    <DesktopView
                        isRotateMobile={isRotateMobile}
                        isMobile={isMobile}
                        images={images}
                        currentImageIndex={currentImageIndex}
                        loading={loading}
                        datosRifa={datosRifa}
                        numResolucion = {numResolucion} />
                </>

            );
        }

        return content;
    }



}


export default VolanteBoleta;

