import React, {Component} from 'react';
import { faMobile} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button} from 'react-bootstrap'
import {Col,Row,Image} from 'react-bootstrap';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import {faFacebookF,faInstagram} from '@fortawesome/free-brands-svg-icons'
import movilgo from '../assets/header/movilgo.png';
import facebook from '../assets/footer/FB.png'
import instagram from '../assets/footer/IN.png'
import cardo from '../assets/footer/cardoso_icon1.png'
import whastapp from '../assets/footer/WA.png'
import {faEnvelope  } from '@fortawesome/free-solid-svg-icons';
import apk from '../utilities/apk/Cliente.apk';
import footer from '../assets/footer/footer_cardoso.css';
import cardoso from '../assets/footer/logo.png';

export default class footer_cardoso extends Component{
    constructor(props) {
        super(props)
        this.state={
                     
        }
      }
      render(){
        return(
            <div style={{backgroundColor:'#0D606E'}}>
                <Row>

                        <Image   className='movilgo'                          
                            style={{width:'20%',height:'30%',marginLeft:'15%',marginBottom:'1%',marginTop: '-1%' }} 
                            src={cardoso}            
                        /> 
                    
                        <Image   className='movilgo'                          
                            style={{width:'20%',height:'30%',marginLeft:'25%',marginBottom:'1%',marginTop: '-1%' }} 
                            src={movilgo}            
                        /> 
                    <Col md={6} xs={6} sm={6} lg={6} className="my-auto footer" >
                        <div style={{display:'flex',marginLeft:'15%',marginTop: '-2%'}}>
                            <Image className='icono1' style={{width:'6%',height:'5%',borderRadius: '10px',marginTop: '3%'}} src={cardo}/>
                            <p className='text1' style={{fontSize:30,color:'white',marginLeft:'2%'}}> Carrera 23e N 62-72 Ed, Pranha, Of. 804 <br/> Manizales - Caldas.</p>
                        </div>
                        <div style={{display:'flex',marginLeft:'15%',marginTop: '-2%'}} >
                            <a target='_blank' href="https://wa.me/573113704937"><Image className='icono2' src={whastapp}/> </a>
                            <p className='text2' style={{fontSize:30,color:'white',marginLeft:'2%'}}>(+57) 321-693-4174 - (+57) 300-128-2759 </p>
                        </div>
                        <div style={{display:'flex',marginLeft:'15%',marginTop: '-1%'}}>
                            <FontAwesomeIcon className='icono3' style={{marginTop:'1%'}} color={'white'} size="3x"icon={faEnvelope} />
                            <p className='text3' style={{fontSize:30,color:'white',marginLeft:'2%',marginTop:'1%'}}>coordinador@grupo-cardoso.com</p>   
                        </div>
                    </Col>
                    <Col md={6} xs={6} sm={6} lg={6} className="my-auto footer" >
                        <div style={{display:'flex',marginLeft:'20%'}}>
                            <a target='_blank' href="https://www.facebook.com/Movilgo"><Image className='icono4' src={facebook}/> </a>
                            <p className='text4' style={{fontSize:30,color:'white',marginLeft:'2%'}}> Movilgo</p>
                        </div>
                        <div style={{display:'flex',marginLeft:'20%'}}>
                            <a target='_blank' href="https://www.instagram.com/movilgored/"><Image className='icono5' src={instagram}/> </a>
                            <p className='text5' style={{fontSize:30,color:'white',marginLeft:'2%'}}> Movilgored</p>
                        </div>

                        <div class="v-line" className='linea1' style={{borderLeft: 'thick solid #ffffff',height:'93%',left: '50%',position: 'absolute',marginTop:'-12%'}}>

                        </div>

                        <div style={{display:'flex',width:'20%',marginLeft:'20%'}}>
                            <div className='icono6'>
                            <a href={apk} target="_blank" rel="noopener noreferrer" download="Cliente.apk" > 
                                <FontAwesomeIcon color={'white'}  fontSize={50} className="mr-2 align-self-center  " icon={faMobile} />                                        
                            </a>
                            </div>
                            <p className='text6' style={{fontSize:30,color:'white',marginLeft:'15%',marginTop:'5%',marginTop:'-1%'}}>App</p>
                        </div>
                        
                    </Col>                    
                </Row>
            </div>
            
            
                
                
          
        )
    }
}   