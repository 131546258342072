import React, { useEffect, useState } from 'react';
import './styles.css'


const SlideAnimation = () => {
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAnimation(true);
        }, 2000);
        // Esperar 2 segundos antes de mostrar la animación

        const animationTimer = setTimeout(() => {
            setShowAnimation(false);
        }, 5000);
        // Mostrar la animación durante 3 segundos

        return () => {
            clearTimeout(timer);
            clearTimeout(animationTimer);
        };
    }, []);

    return (
        <div style={{ position: 'absolute', top: '30%', left: '70%', transform: 'translate(-50%, -50%)', zIndex: '3' }}>
            {showAnimation && (
                <div className="slide-animation">
                    <span>Deslizar para ver mas</span>
                </div>
            )}

            {/* Resto del contenido */}
        </div>
    );
};

export default SlideAnimation;

