import React, { PureComponent } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/modalNotificationTransaction';
//import { cambiarContrasena } from '../../../services/nuevaContraseña.service';
import { ValidarNumero } from '../../../services/rifa.service';
import {Image} from 'react-bootstrap';
class ValidarBoleta extends PureComponent {
   
    constructor(props) {
        super(props);
        /* this.recargas = this.props.recargas; */
        this.state = {
            pdf:'',
            loading:true
        }
    }

    
    componentDidMount = async() => {
        console.log("ingreso al validarBoletas")
       this.loaData()

    }
    loaData=()=>{
        let url = window.location.search.split("id=");
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        //console.log("validar boleta rifa",rifa)
        //console.log("url del validar voleta",url)
        let id = url[1];
        console.log("id de la busqueda",id)
        
        ValidarNumero(id,(flag, response)=>{
            console.log("flag del  ValidarBoleta",flag)
            console.log("response del  ValidarBoleta",response)
        if(flag){
            this.setState({pdf:response.datos.pdf,loading:false})
            this.downloadPDF(response.datos.pdf)

            //alert("¡ Haz cambiado tu ValidarBoleta, ya puedes ingresar de nuevo a tu cuenta MITECNOVA !");
            //window.location = "/";
        }else{
            alert("Error","Se presento un error al validar la boleta")
            this.setState({loading:false})
            //alert("¡¡ Ups!!!, Ocurrio algo, intenta de nuevo más tarde !");
        }
        
        })
    }   
        downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "RifasMovilgo.pdf";
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    } 

    render() {

        return (
            <>
                <style>
                    {`                
                        .pdfImage{
                            color: white;
                            text-align: center;
                            transition: all 0.4s ease 0s;
                        }
                        .pdfImage:hover{
                            transform: translateY(-7px);
                            cursor: pointer;
                        }
                    `}
                </style>
                <div style={{height:750}}>
                    {this.state.pdf.length>0&&<div style={{width:'100%',height:'95%'}}>
                        <embed src={`data:application/pdf;base64,${this.state.pdf}`} height={800} width={'100%'}/> 
                    </div>  }
                    
                </div>
                
                {this.state.modalNotification && <ModalNotificationTransaction closeModal={(flag) => this.closeModal(flag)} type={"Recarga"} message={"Recarga realizada exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />}
        </>
        )
    }
}


export default ValidarBoleta;

const styles={
    container: {
        height: '90px',
        width: '90px',
        margin: '15px'
    }
}